body {
  line-height: 1;
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  border: 0px;
  vertical-align: middle;
  background: #000000;
}

* {
  font-family: Montserrat, sans-serif;
  transition: all 0.25s ease 0s;
  color: #ffffff;
}

a {
  text-decoration: none;
}

img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

body img {
  height: auto;
  max-width: 100%;
}

*, ::before, ::after {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'aBlackLives';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('./assets/fonts/ablacklives/aBlackLives.ttf') format('truetype');
}

.App {
  width: 100%;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.custom-span {
  font-family: "aBlackLives", Sans-serif;
}

.cCDhqr {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  padding-top: 15px;
  padding-bottom: 15px;
  background: transparent;
}

@media (min-width: 1200px) {
      .cCDhqr {
          padding: 28px 0px 28px;
      }
}

.eFYKam {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  padding-top: 15px;
  padding-bottom: 15px;
  background: rgb(7, 7, 8);
}

@media (min-width: 1200px) {
  .eFYKam {
      padding: 28px 0px 28px;
  }
}

.gQPvs {
  max-width: 1200px;
  width: 100%;
  display: flex;
  place-content: flex-start;
  align-items: flex-start;
  -webkit-box-pack: start;
  margin: 0px auto;
  padding: 0px 35px;
  position: relative;
}

@media (min-width: 1200px) {
      .gQPvs {
          padding: 0px;
      }
}

.gETbUR {
  -webkit-box-pack: justify;
  place-content: center space-between;
  -webkit-box-align: center;
  align-items: center;
}
  
.dOjLXf {
  display: flex;
  place-content: center flex-start;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  gap: 35px;
}

.kDpGQw {
  display: flex;
  place-content: center flex-start;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  text-decoration: none;
  cursor: pointer;
}

.kDpGQw {
  width: 100%;
  max-width: 120px;
  font-size: 24px;
}

@media (min-width: 768px) {
      .kDpGQw {
          max-width: 193px;
          font-size: 36px;
      }
}

.kFYxgS {
  display: none;
  place-content: center flex-start;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  gap: 24px;
}

@media (min-width: 1200px) {
      .kFYxgS {
          display: flex;
      }
}

.lhLZgp {
  position: relative;
}

.cHFckh {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid transparent;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
  gap: 6px;
}

.eKgrXn {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid transparent;
  text-decoration: none;
  cursor: pointer;
}

.gyekPf {
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-end;
  -webkit-box-pack: end;
  gap: 21px;
  display: flex;
  width: fit-content;
  flex-wrap: nowrap;
}

.FSAUR {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  gap: 7px;
  width: 190px;
}

.gyekPf .sc-hBxehG {
  display: none;
}

@media (min-width: 1200px) {
      .gyekPf .sc-hBxehG {
          display: flex;
      }
}

.jOskAN {
  min-width: 46.5px;
  height: 46.5px;
  border-radius: 50%;
  position: relative;
  background: rgb(27, 27, 31);
}

.jWrwUx {
  width: 36px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.jOskAN img {
  position: absolute;
}

.dIcfzu {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}

.ddMbHC {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.gynApa {
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  white-space: nowrap;
  -webkit-box-align: baseline;
  align-items: baseline;
  gap: 12px;
}

.diRBsb {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 11px 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  outline: none;
  background: transparent;
  font-style: normal;
  text-align: center;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
  gap: 10px;
  border-radius: 60px;
  text-decoration: none;
}

@media (min-width: 768px) {
      .diRBsb {
          font-size: 14px;
          line-height: 17px;
          padding: 16px 32px;
      }
}

.diRBsc {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 11px 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  outline: none;
  background: transparent;
  font-style: normal;
  text-align: center;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
  gap: 10px;
  border-radius: 60px;
  text-decoration: none;
}

@media (min-width: 768px) {
      .diRBsc {
          font-size: 14px;
          line-height: 17px;
          padding: 8px 16x;
      }
}

.eHMfMO {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  place-content: flex-end flex-start;
  -webkit-box-pack: start;
  position: relative;
  width: 26px;
  min-height: 10px;
}

@media (min-width: 1200px) {

      .eHMfMO {
          display: none;
      }
  }

  .percent-change {
    margin-left: 4px;
    font-size: 12px !important;
  }

  .green {
    color: #8bbb4c;
  }

  .red {
    color: #ea3943
  }

  .blue {
    color: #2ea7de;
  }

  .eHMfMO span {
    width: 26px;
    height: 3px;
    background: rgb(255, 255, 255);
    position: absolute;
    left: 0px;
    top: 0px;
}

.eHMfMO span:nth-child(2) {
  left: unset;
  top: unset;
  right: 0px;
  bottom: 0px;
  opacity: 0.4;
  width: 10px;
}

.fcAAV {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  place-content: flex-end flex-start;
  -webkit-box-pack: start;
  position: relative;
  width: 26px;
  min-height: 10px;
}

@media (min-width: 1200px) {

      .fcAAV {
          display: none;
      }
}

.fcAAV span {
  width: 26px;
  height: 3px;
  background: rgb(255, 255, 255);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateZ(45deg);
}

.fcAAV span:nth-child(2) {
  opacity: 1;
  width: 26px;
  right: unset;
  bottom: unset;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotateZ(135deg);
}

.esDaVp {
  visibility: hidden;
  opacity: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  inset: 0px;
  background: rgba(14, 14, 14, 0.4);
  backdrop-filter: blur(22px);
  z-index: 100;
}

.itetuu {
  display: flex;
  align-items: flex-start;
  place-content: flex-start;
  -webkit-box-pack: start;
  flex-direction: column;
  padding-left: 35px;
  padding-top: 100px;
  gap: 25px;
  overflow-y: scroll;
  height: 100vh;
  padding-bottom: 120px;
}

.XuMrk {
  visibility: visible;
  opacity: 1;
  width: 100%;
  height: 100vh;
  position: fixed;
  inset: 0px;
  background: rgba(14, 14, 14, 0.4);
  backdrop-filter: blur(22px);
  z-index: 100;
}

.bVfGaI {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid transparent;
  text-decoration: none;
  cursor: pointer;
}

.bVZDti {
  background: rgba(29, 50, 75, 0.4);
  backdrop-filter: blur(10px);
  padding: 9px;
  border-radius: 10px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
  gap: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: rgb(255, 255, 255);
  text-decoration: none;
  min-width: 240px;
}

.bIcdpu {
  border-radius: 12px;
  width: 39px;
  height: 39px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
}

.dogemob-app {
  width: 100%;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.noise {
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  background: url(./assets/images/noise.259badb9c3e80bcadbf0.webp);
  z-index: 20;
  opacity: 0.3;
  mix-blend-mode: overlay;
  pointer-events: none;
}

.imBhgw {
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
}

@media (min-width: 768px) {
      .imBhgw {
          min-height: 100vh;
          background: url(./assets/images/feather.png) center top / auto 100% no-repeat;
      }
}

.jDrdFD {
  max-width: unset;
  width: auto;
  min-height: 435px;
}

@media (min-width: 768px) {
      .jDrdFD {
          display: none;
      }
}

.iiWBxn {
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: column;
}

@media (min-width: 768px) {
      .iiWBxn {
          position: relative;
          flex: 1 1 0%;
          -webkit-box-pack: end;
          justify-content: flex-end;
      }
}

.fWCKvA {
  padding-left: 46px;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  position: relative;
  color: rgb(255, 255, 255);
}

.fWCKvA::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 90px;
  background: rgb(255, 255, 255);
  left: -3px;
  top: 0px;
}

.fWCKvA img {
  max-width: 300px;
}

@media (min-width: 768px) {
      .fWCKvA {
          position: absolute;
          top: 127px;
          right: 0px;
          max-width: 291px;
          padding-bottom: 0px;
      }
      
}

.fWCKvD {
  padding-left: 46px;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  color: rgb(255, 255, 255);
  padding-bottom: 40px;
}

.fWCKvD::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 36px;
  background: rgb(255, 255, 255);
  left: -3px;
  top: 0px;
}

@media (min-width: 768px) {
      .fWCKvD {
          position: absolute;
          bottom: 287px;
          left: 0px;
          padding-bottom: 10px;
      }
}

.fWCKvD span {
  padding: 2px 4px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.3);
}

.fWCKvS {
  padding-left: 46px;
  font-size: 16px;
  font-weight: 400;
  line-height: 160%;
  position: relative;
  color: rgb(255, 255, 255);
  padding-bottom: 40px;
  background: rgba(0, 0, 0, 0.651);
}

@media (min-width: 768px) {
      .fWCKvS {
          position: absolute;
          bottom: 287px;
          left: 0px;
          padding: 18px;
      }
}

.fWCKvS span {
  padding: 2px 4px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.3);
}


.hVIgPF {
  width: 100%;
  background: url(./assets/images/b3.png) center top / 100% 100% no-repeat;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 46px 28px 25px 21px;
}

@media (min-width: 768px) {
      .hVIgPF {
          padding: 0px;
          flex-direction: row;
          gap: 0px;
          background: url(./assets/images/b2.png) center top / 100% 100% no-repeat;
      }
}

.djSBVF {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-pack: start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 23px;
  pointer-events: auto;
  cursor: pointer;
}

@media (min-width: 768px) {
      .djSBVF {
          border-right: 1px solid rgba(255, 255, 255, 0.1);
          padding-top: 47px;
          padding-bottom: 48px;
          flex-direction: column;
          -webkit-box-pack: center;
          justify-content: center;
          gap: 28px;
          max-width: calc(20% - 0.8px);
      }
}

.djSBVF:last-child {
  border-right: 1px solid transparent
}

.dkmaOE {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(217, 217, 217, 0.1);
}

@media (min-width: 768px) {
      .dkmaOE {
          width: 107px;
          max-width: unset;
          height: 107px;
      }
}

.dkmaOE svg {
  width: 28px;
  height: 28px;
}

@media (min-width: 768px) {
      .dkmaOE svg {
          width: 65px;
          height: 65px;
      }
}

.yrsAb {
  display: flex;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
}

@media (min-width: 768px) {
      .yrsAb {
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
      }
}

.cRaBsI {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (min-width: 768px) {
      .cRaBsI {
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          gap: 5px;
      }
}

.feWOU {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

@media (min-width: 768px) {
      .feWOU {
          font-size: 22px;
      }
}

.lifaGf {
  color: rgb(255, 210, 103);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  opacity: 1;
}

@media (min-width: 768px) {
      .yrsAb > svg {
          display: none;
      }
}

.OFNPA {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-pack: start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 23px;
  pointer-events: none;
}

@media (min-width: 768px) {
      .OFNPA {
          border-right: 1px solid rgba(255, 255, 255, 0.1);
          padding-top: 47px;
          padding-bottom: 48px;
          flex-direction: column;
          -webkit-box-pack: center;
          justify-content: center;
          gap: 28px;
          max-width: calc(20% - 0.8px);
      }
}

@media (min-width: 768px) {
      .OFNPA:last-child {
          border-right: none;
      }
}

.eRLweV {
  color: rgb(255, 210, 103);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  opacity: 0.5;
}

.eiCnHU {
  z-index: 10;
  margin-top: 9px;
}

@media (min-width: 768px) {
      .eiCnHU {
          margin-top: 0px;
      }
}

.ghtYsH {
  background: url(./assets/images/b4.png) center center / 100% 100% no-repeat;
  padding: 49px 16px 61px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
  gap: 41px;
  flex-direction: column;
}

@media (min-width: 768px) {
      .ghtYsH {
          background: url(./assets/images/b1.png) center center / 100% 100% no-repeat;
          flex-direction: row;
          padding: 72px 67px 73px 57px;
      }
}

.eJlFOo {
  font-family: "aBlackLives", Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 90%;
  letter-spacing: 0.04em;
  color: rgb(217, 217, 217);
  text-transform: uppercase;
  text-align: center;
}

@media (min-width: 768px) {
      .eJlFOo {
          text-align: left;
      }
}

.fkCKDU {
  background: rgb(18 63 112 / 41%);
  border-radius: 10px;
  padding: 24px 16px 24px 24px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center space-between;
  -webkit-box-pack: justify;
  position: relative;
  width: 100%;
}

@media (min-width: 768px) {
      .fkCKDU {
          padding: 29.5px 35px 29.5px 31px;
      }
}

.kMWtxG {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
  gap: 19px;
  max-width: calc(100% - 59px);
}

@media (min-width: 768px) {
      .kMWtxG {
          gap: 22px;
          max-width: calc(100% - 97px);
      }
}

.kvigvj {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 119%;
  color: rgb(255, 255, 255);
  overflow: hidden;
  word-break: break-all;
  max-height: 24px;
}

.cGafjR {
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;
  padding: 0px;
}

.cGafjR > svg {
  font-size: 32px;
  opacity: 0.2;
}

.jwFOPZ {
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  text-align: right;
  color: rgba(255, 255, 255, 0.4);
  display: none;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
  gap: 11px;
  bottom: -46px;
  right: 48px;
}

@media (min-width: 768px) {
      .jwFOPZ {
          display: flex;
      }
}

.dtJqJL {
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  text-align: right;
  color: rgba(255, 255, 255, 0.4);
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
  gap: 11px;
  bottom: 0px;
  right: 20px;
  display: flex;
}

.iGMHVy {
  width: 100%;
  position: relative;
  margin-top: 60px;
}

@media (min-width: 768px) {
    .iGMHVy {
          margin-top: 0px;
          padding-top: 243px;
          min-height: 971px;
      }
}

.kCUYMN {
  position: absolute;
  top: 4px;
  left: -80px;
  transform: rotate(40deg);
  display: none;
}

@media (min-width: 1808px) {
    .kCUYMN {
          display: inline;
          top: -220px;
      }
}

.BjXji {
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  align-content: center;
  gap: 36px;
}
  
@media (min-width: 768px) {
      .BjXji {
          align-items: flex-start;
          align-content: flex-start;
      }
}

.eXvJLB {
  min-width: 447px;
  margin-bottom: -18px;
}

.BjXji > * {
  z-index: 2;
}

@media (min-width: 768px) {
      .eXvJLB {
          margin-bottom: 0px;
          min-width: 0px;
          position: absolute;
          top: -260px;
          left: calc(50% + 340px);
          transform: translateX(-50%);
          z-index: 1;
      }
}

.ccvWeK {
  font-style: normal;
  margin: 0px;
  font-family: "aBlackLives", Sans-serif;
  font-weight: 400;
  font-size: 59px;
  line-height: 80%;
  letter-spacing: 0.04em;
  padding-bottom: 20px;
  text-transform: uppercase;
  color: rgb(217, 217, 217);
  position: relative;
  width: 100%;
}

@media (min-width: 768px) {
      .ccvWeK {
          width: unset;
          font-size: 99px;
          padding-bottom: 16px;
          line-height: 80%;
          align-items: flex-end;
      }
}

.ccvWeK::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  max-width: 388px;
  background-color: rgba(255, 255, 255, 0.3);
  left: 0px;
  bottom: 0px;
}

.ccvWeK::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  left: 0px;
  bottom: -3px;
  max-width: 137px;
}

.ccvWedK {
  font-style: normal;
  margin: 0px;
  font-family: "aBlackLives", Sans-serif;
  font-weight: 400;
  font-size: 69px;
  line-height: 80%;
  letter-spacing: 0.04em;
  padding-bottom: 20px;
  text-transform: uppercase;
  color: rgb(217, 217, 217);
  position: relative;
  width: 100%;
}

@media (min-width: 768px) {
      .ccvWedK {
          width: unset;
          font-size: 99px;
          padding-bottom: 16px;
          line-height: 80%;
          align-items: flex-end;
          text-align: right;
      }
}


.ccvWeKa {
  font-style: normal;
  margin: 0px;
  font-family: "aBlackLives", Sans-serif;
  font-weight: 400;
  font-size: 59px;
  line-height: 80%;
  letter-spacing: 0.04em;
  padding-bottom: 20px;
  text-transform: uppercase;
  color: rgb(217, 217, 217);
  position: relative;
  width: 100%;
}

@media (min-width: 768px) {
      .ccvWeKa {
          width: unset;
          font-size: 74px;
          padding-bottom: 16px;
          line-height: 80%;
          align-items: flex-end;
          text-align: right;
      }
}

.ccvWeKa::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  max-width: 388px;
  background-color: rgba(255, 255, 255, 0.3);
  left: 0px;
  bottom: 0px;
}

.ccvWeKa::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  left: 0px;
  bottom: -3px;
  max-width: 137px;
}

.eUHxTz {
  max-width: 433px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  -webkit-box-pack: start;
  place-content: flex-start;
  gap: 24px;
}

.hkMEWj {
  font-weight: 400;
  width: 100%;
  font-size: 14px;
  line-height: 160%;
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
      .hkMEWj {
          font-size: 16px;
      }
}

.kTExUw {
  display: flex;
  align-items: flex-end;
  place-content: center flex-start;
  -webkit-box-pack: start;
  gap: 33px;
}

.jZXXD {
  width: 300px;
  height: 150px;
  background-image: url(./assets/images/b3.png);
  background-size: 100% 100%;
  background-position: center center;
  padding: 10px;
  max-width: 100%;
}

@media (min-width: 768px) {
      .jZXXD {
          padding: 20px;
          width: 472px;
          height: 272px;
      }
}

.bLbXqJ {
  width: 100%;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
  background: black;
  position: relative;
}

.jEqvyF {
  padding-left: 21px;
  font-weight: 500;
  font-size: 14px;
  display: none;
  margin-top: 35px;
  max-width: 160px;
  align-items: flex-start;
  place-content: flex-start;
  flex-direction: column;
  -webkit-box-pack: start;
  gap: 15px;
  line-height: 160%;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat, sans-serif;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
      .jEqvyF {
          padding-bottom: 0px;
          display: flex;
      }
}

.jEqvyF::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 21px;
  background: rgb(255, 255, 255);
  left: -3px;
  top: 0px;
}

.bozcar {
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  align-content: center;
  padding-top: 100px;
}

@media (min-width: 768px) {
      .bozcar {
          padding-top: 185px;
          min-height: calc(906px);
      }
}

@media (min-width: 768px) {
      .bSREpq {
          -webkit-box-pack: end;
          justify-content: flex-end;
      }
    }

.dniiSB {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      place-content: flex-end;
      -webkit-box-pack: end;
      gap: 24px;
}

@media (min-width: 768px) {
      .dniiSB {
          gap: 54px;
      }
}

.bFtCoT {
  max-width: 433px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  -webkit-box-pack: end;
  place-content: flex-end;
  text-align: right;
}

.jvvSFb {
  font-weight: 400;
  width: 100%;
  font-size: 14px;
  line-height: 160%;
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
      .jvvSFb {
          font-size: 16px;
      }
}

.diRBsb {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 11px 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  outline: none;
  background: transparent;
  font-style: normal;
  text-align: center;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
  gap: 10px;
  border-radius: 60px;
}

@media (min-width: 768px) {
      .diRBsb {
          font-size: 14px;
          line-height: 17px;
          padding: 16px 32px;
      }
}

.diRBsa {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 4px 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  outline: none;
  background: transparent;
  font-style: normal;
  text-align: center;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
  gap: 10px;
  border-radius: 60px;
  width: fit-content;
  background: white;
  margin-top: 8px;
}

@media (min-width: 768px) {
      .diRBsa {
          font-size: 14px;
          line-height: 17px;
          padding: 8px 16px;
      }
}

.bFtCoT .sc-gswNZR {
  min-width: 252px;
  padding: 25px 60px;
  border-width: 2px;
}

.dmUbjx {
  position: relative;
  min-width: 605px;
  margin-top: -20px;
  left: 50%;
  transform: translateX(-25%);
  margin-bottom: 100px;
}

@media (min-width: 768px) {
      .dmUbjx {
          margin-bottom: unset;
          margin-top: unset;
          position: absolute;
          top: 120px;
          left: calc(50% - 300px);
          transform: translateX(-50%);
          z-index: 1;
          max-width: 1002px;
      }
}

.jzMUTv {
  padding-top: 14px;
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  align-content: center;
}

@media (min-width: 768px) {
      .jzMUTv {
          padding-top: 185px;
          min-height: calc(906px);
      }
}

.haSfvH {
  min-width: 545px;
  display: none;
}

@media (min-width: 768px) {
      .haSfvH {
          display: inline;
          position: absolute;
          top: -122px;
          right: calc(50% - 405px);
          transform: translateX(50%);
          z-index: 1;
      }
}

.glspdd {
  position: absolute;
  bottom: 0px;
  min-width: 700px;
  right: -350px;
}

@media (min-width: 768px) {
      .glspdd {
        transform: rotate(36deg);
        bottom: unset;
        top: 50%;
        left: -600px;
        opacity: 0.4;
      }
}

.glspded {
  display: none;
}

@media (min-width: 768px) {
  .glspded {
    transform: rotate(340deg);
    position: absolute;
    bottom: 60%;
    min-width: 700px;
    right: -523px;
    display: flex !important;
    opacity: 0.4;
}
}

.gNRRgK {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  place-content: flex-start;
  -webkit-box-pack: start;
  gap: 54px;
  z-index: 4;
}

@media (min-width: 768px) {
      .gNRRgK {
          gap: 84px;
      }
}

.bqSCLj {
  display: flex;
  -webkit-box-align: stretch;
  align-items: stretch;
  place-content: center flex-start;
  -webkit-box-pack: start;
  gap: 59px;
  width: 100%;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .bqSCLj {
          gap: 91px;
          flex-wrap: nowrap;
      }
}

.pnegl {
  display: flex;
  align-items: flex-start;
  place-content: flex-start space-between;
  -webkit-box-pack: justify;
  flex-direction: column;
  gap: 7px;
}

.hPpbma {
  display: flex;
  align-items: flex-start;
  place-content: flex-start;
  -webkit-box-pack: start;
  gap: 6px;
  flex-direction: column;
}

.cEcqQq {
  display: flex;
  align-items: flex-end;
  place-content: flex-end flex-start;
  -webkit-box-pack: start;
}

.cEcqQq div {
  font-weight: 400;
  font-size: 150px;
  line-height: 80%;
  font-family: "aBlackLives", Sans-serif;
}

.cEcqQq span {
  margin-left: -0.04em;
  font-family: Oranienbaum, serif;
  color: rgba(255, 255, 255, 0.5);
  font-size: 59px;
  line-height: 80%;
}

.dmyKkk {
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: rgba(255, 255, 255);
  opacity: 0.5;
}

.eJLCbn {
  padding-left: 21px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  margin-top: 35px;
  align-items: flex-start;
  place-content: flex-start;
  flex-direction: column;
  -webkit-box-pack: start;
  gap: 15px;
  line-height: 160%;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat, sans-serif;
}

@media (min-width: 768px) {
      .eJLCbn {
          padding-bottom: 0px;
      }
}

.eJLCbn::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 36px;
  background: rgb(255, 255, 255);
  left: -3px;
  top: 0px;
}


.gasgWt {
  font-style: normal;
  margin: 0px;
  font-family: "aBlackLives", Sans-serif;
  font-weight: 400;
  font-size: 39px;
  line-height: 80%;
  letter-spacing: 0.04em;
  padding-bottom: 20px;
  text-transform: uppercase;
  color: rgb(217, 217, 217);
  position: relative;
  width: 100%;
}

@media (min-width: 768px) {
      .gasgWt {
          width: unset;
          font-size: 81px;
          padding-bottom: 16px;
          line-height: 80%;
      }
}

.gasgWt::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  max-width: 388px;
  background-color: rgba(255, 255, 255, 0.3);
  left: 0px;
  bottom: 0px;
}

.gasgWt::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  left: 0px;
  bottom: -3px;
  max-width: 137px;
}

.gasgWr {
  display: flex;
  gap: 24px;
  z-index: 4;
  flex-direction: column;
}


@media (min-width: 768px) {
  .gasgWr {
      flex-direction: row;
  }
}

.fceYE {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  align-content: center;
  padding-top: 105px;
}

@media (min-width: 768px) {
      .fceYE {
          padding-top: 193px;
      }
}

.kiWAbQ {
  -webkit-box-align: stretch;
  align-items: stretch;
  flex-direction: column-reverse;
}

@media (min-width: 768px) {
      .kiWAbQ {
          flex-direction: row;
      }
}

.JCZao {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-end;
  -webkit-box-pack: end;
  width: 100%;
  position: relative;
}

@media (min-width: 768px) {
      .JCZao {
          align-items: flex-start;
          place-content: flex-start flex-end;
          -webkit-box-pack: end;
          max-width: calc(50%);
      }
}

.fZIdQF {
  position: absolute;
  max-width: 100%;
  left: 65%;
  top: 40px;
  transform: translate(-50%, 0px);
}

@media (min-width: 768px) {
      .fZIdQF {
          transform: unset;
          top: unset;
          left: 0px;
          max-width: 1080px;
      }
  }

  .fMlEwF {
    width: 100%;
    max-width: 300px;
    position: relative;
    height: 328px;
}

@media (min-width: 768px) {
     .fMlEwF {
          margin-top: 70px;
      }
}

.ehWWpn {
  position: absolute;
  right: 0px;
  width: 252px;
  height: 206px;
  border-radius: 50%;
  background: rgb(88, 170, 255);
  filter: blur(95px);
}

.lfthbD {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -96px;
  max-width: 300px;
}

.yaZYU {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 11px 16px;
  border: none;
  outline: none;
  background: rgb(255, 255, 255);
  font-style: normal;
  text-align: center;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
  gap: 10px;
  border-radius: 60px;
}

@media (min-width: 768px) {
      .yaZYU {
          font-size: 14px;
          line-height: 17px;
          padding: 16px 32px;
      }
}

.jMUiND {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  max-width: 200px;
  width: 100%;
  padding: 25px 30px;
}

.jMUiNT {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 210px;
  max-width: 200px;
  width: 100%;
  padding: 25px 30px;
}
.hvuOkT {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  place-content: flex-end flex-start;
  -webkit-box-pack: start;
  width: 100%;
}

@media (min-width: 768px) {
      .hvuOkT {
          max-width: calc(50%);
      }
}

.faAtLm {
  display: flex;
  flex-direction: column;
  gap: 42px;
  align-items: flex-start;
  place-content: flex-start;
  -webkit-box-pack: start;
  text-align: right;
  z-index: 4;
}

.erQkjj {
  font-size: 49px;
}

@media (min-width: 768px) {
      .erQkjj {
          font-size: 69px;
      }
}

.sVIXb {
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: rgb(255, 255, 255);
}

@media (min-width: 768px) {
      .sVIXb {
          font-size: 16px;
          max-width: 480px;
      }
}

.sVIXb span {
  padding: 2px 4px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.3);
}

.cMZZVA {
  width: 100%;
  height: 112px;
  position: relative;
  max-width: 516px;
  background: url(./assets/images/MysterySubstract.c172afb98c87ccdac76b.webp) center center / 100% 100% no-repeat;
  margin-top: 70px;
}

@media (min-width: 768px) {
      .cMZZVA {
          height: 168px;
      }
}

.keajoW {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center space-between;
  -webkit-box-pack: justify;
  padding: 0px 30px;
  width: 100%;
  transform: translate(-50%, -50%);
}

.dFZgJn {
  max-width: 117px;
}

@media (min-width: 768px) {
      .dFZgJn {
          max-width: 175px;
      }
}

.jLDguP {
  border-radius: 50%;
  width: 41px;
  height: 41px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
  margin: 0 auto;
  margin-top: 134px;
}

@media (min-width: 768px) {
      .jLDguP {
          width: 62px;
          height: 62px;
      }
}

.jLDguP span {
  font-weight: 400;
  font-size: 35px;
  line-height: 80%;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
}

@media (min-width: 768px) {
      .jLDguP span {
          font-size: 49px;
      }
}

.gBPPpE {
  max-width: 109px;
}

@media (min-width: 768px) {
      .gBPPpE {
          max-width: 142px;
      }
}

.dAiEII {
  padding-bottom: 0px;
  text-align: center;
  margin: 160px auto 0px;
}

.gNauve {
  flex-flow: wrap;
  -webkit-box-pack: justify;
  place-content: center space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 22px;
  gap: 10px;
  z-index: 4;
  justify-content: center;
}

@media (min-width: 968px) {
  .gNauve {
      margin-top: 74px;
      gap: 48px;
      flex-wrap: nowrap;
  }
}

.gNauvd {
  flex-flow: wrap;
  -webkit-box-pack: justify;
  place-content: center;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 22px;
  gap: 10px;
  z-index: 4;
}

@media (min-width: 968px) {
  .gNauvd {
      margin-top: 74px;
      gap: 48px;
      flex-wrap: nowrap;
  }
}

.gNauvd a {
  max-width: calc(50% - 5px);
}

@media (min-width: 768px) {
      .gNauvd a {
          max-width: calc(33.3333% - 6.66667px);
      }
}

@media (min-width: 968px) {
      .gNauvd a {
          max-width: calc(16.6667% - 40px);
      }
}

.kUvDJu {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
}

.gNauve a {
  max-width: calc(50% - 5px);
  height: 58px;
}

@media (min-width: 768px) {
      .gNauve a {
          max-width: calc(33.3333% - 6.66667px);
          height: 58px;
      }
}

@media (min-width: 968px) {
      .gNauve a {
          max-width: calc(16.6667% - 40px);
          height: 58px;
      }
}

.no-phone {
  display: none;
}

@media (min-width: 968px) {
  .no-mac {
      display: none;
  }
  .no-phone {
    display: block !important;
  }
}

.gbmyFo {
  margin-top: 125px;
  position: relative;
}

@media (min-width: 768px) {
      .gbmyFo {
          padding-top: 217px;
          margin-top: 61px;
      }
}

.eirPYJ {
  position: absolute;
  top: 191px;
  left: 133px;
  transform: translateX(-100%);
  width: 665px;
  height: 768px;
  background: rgb(227 75 164);
  filter: blur(150px);
  display: none;
}

@media (min-width: 768px) {
      .eirPYJ {
          display: block;
      }
}

.bNKOrk {
  left: unset;
  top: 0px;
  transform: translateX(100%);
  right: 225px;
}

.hnmivc {
  position: absolute;
  max-width: 148px;
  top: 0px;
  right: 194px;
  z-index: 10;
  display: none;
}

@media (min-width: 968px) {
      .hnmivc {
          display: block;
      }
}

.kdaIjJ {
  padding-bottom: 0px;
  text-align: center;
  margin: 0px auto;
  position: relative;
  z-index: 10;
}

.gXVxC {
  display: block;
  margin-top: 76px;
  position: relative;
  z-index: 10;
  overflow: auto;
  padding: 0px 15px;
}

@media (min-width: 768px) {
      .gXVxC {
          padding: 0px;
      }
}

.bgVemk {
  width: 100%;
  padding: 45px 21px 36px 36px;
  background: url(./assets/images/b4.png) center top / 100% 100% no-repeat;
  background-color: white;
  height: 100%;
  min-width: 322px;
}

@media (min-width: 768px) {
      .bgVemk {
          width: 366px;
          padding: 58px 41px 39px;
      }
}

.lbKNTY {
  margin: 0;
  font-size: 39px;
  line-height: 80%;
  font-family: "aBlackLives", sans-serif;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  color: #080808;
}

@media (min-width: 768px) {
  .lbKNTY {
    font-size: 29px;
  }
}

.fsqGzr {
  margin-top: 13px;
  padding-left: 20px;
}

@media (min-width: 768px) {
  .fsqGzr {
    margin-top: 23px;
  }
}

.gdAppR {
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  white-space: normal;
  color: #070708;
}

.gagKga {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
}

@media (min-width: 2568px) {
  .gXVxC {
    flex-basis: calc(16% - 16px);
  }
}

.iriFHz {
  position: relative;
  padding-top: 125px;
}

@media (min-width: 768px) {
      .iriFHz {
          background-position: center -186px;
          background-size: cover;
          padding-top: 215px;
          padding-bottom: 24px;
      }
}

.bWnAzg {
  padding-bottom: 0px;
  text-align: center;
  margin: 0px auto;
  position: relative;
  z-index: 10;
}

.hLZNOT {
  margin-top: 47px;
  flex-wrap: wrap;
  gap: 30px;
  -webkit-box-pack: center;
  justify-content: center;
}

.dAHmVc {
  display: flex;
  flex-direction: column;
  place-content: center flex-start;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  gap: 12px;
  z-index: 4;
}

@media (min-width: 968px) {
      .dAHmVc:nth-child(2), .dAHmVc:nth-child(4) {
          margin-top: 60px;
      }
}

.duJmoH {
  position: relative;
  width: 200px;
  height: 200px;
  border: 1px solid rgba(255, 211, 136, 0.1);
  border-radius: 50%;
  padding: 12px;
}

.duJmoH > div:first-child {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid rgb(255, 211, 136);
  padding: 13px;
  position: relative;
}

.duJmoH > div:first-child > div {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(27, 27, 31, 0.4);
  position: relative;
}

.fbbTMP {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: rgb(255, 211, 136);
  filter: blur(60px);
  position: absolute;
  top: 16px;
  right: 16px;
}

.bECFWj {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.igvPvp {
  width: 42px;
  height: 42px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
  border-radius: 50%;
  background-color: rgb(255, 211, 136);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -9px;
}

.dsIoaT {
  display: flex;
  flex-direction: column;
  place-content: center flex-start;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
}

.hIuwy {
  margin: 0px;
  font-weight: 400;
  font-size: 30px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  font-family: "aBlackLives", Sans-serif;
  color: rgb(255, 255, 255);
}

.erPLj {
  margin: 0px;
  font-weight: 400;
  font-size: 22px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  font-family: "aBlackLives", Sans-serif;
  color: rgb(106, 106, 106);
}

.erPLjx {
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  font-family: "aBlackLives", Sans-serif;
  color: rgb(106, 106, 106);
}

.erPLjy {
  margin: 0px;
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  font-family: "aBlackLives", Sans-serif;
  color: rgb(106, 106, 106);
}

.iXTXYy {
  width: 100%;
  margin-top: 42px;
  position: relative;
  display: flex;
  flex-direction: column;
  place-content: center flex-start;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
}

@media (min-width: 768px) {
      .iXTXYy {
          margin-top: 240px;
          min-height: calc(715px);
      }
}

.gdXgRo {
  position: absolute;
  top: -540px;
  left: -700px;
  transform: rotate(45deg);
  opacity: 0.5;
}



.eEZKoh {
  width: 500px;
  max-width: unset;
  margin-bottom: 40px;
  margin-left: 60px;
}

@media (min-width: 768px) {
      .eEZKoh {
          width: unset;
          position: absolute;
          top: -250px;
          left: calc(50% - 246px);
          transform: translateX(-50%);
          z-index: 1;
          width: 1048px !important;
          margin-left: 0px !important;
      }
}

@media (min-width: 768px) {
      .dDZqqI {
          -webkit-box-pack: end;
          justify-content: flex-end;
      }
}

.enRuIl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  place-content: flex-start;
  -webkit-box-pack: start;
  position: relative;
}

@media (min-width: 768px) {
      .enRuIl {
          width: 50%;
      }
  }

  .cRwEgx {
    position: absolute;
    right: -162px;
    top: -260px;
    transform: rotateZ(71deg);
    z-index: -1;
    display: none;
    filter: blur(8px);
}

@media (min-width: 768px) {
    .cRwEgx {
          display: inline;
      }
}

.gDsIaM {
  padding-bottom: 0px;
}

.eZdyRc {
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  color: rgb(255, 255, 255);
  max-width: 380px;
  margin-top: 25px;
  text-align: right;
}

.eZdyRc span {
  padding: 2px 4px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.3);
}

.iTkrBb {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
  gap: 10px;
  margin-top: 52px;
  flex-wrap: wrap;
  z-index: 4;
}

@media (min-width: 768px) {
      .iTkrBb {
          flex-wrap: nowrap;
      }
}

.iTkrBZ {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
  gap: 4px;
  margin-top: 12px;
  flex-wrap: wrap;
  z-index: 4;
}

@media (min-width: 768px) {
      .iTkrBZ {
          flex-wrap: nowrap;
      }
}

.cyBHaY {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgb(27, 27, 31);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
}

.cyBHaY > svg {
  font-size: 21px;
}

.cyBHaZ {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: rgb(27, 27, 31);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
}

.cyBHaZ > svg {
  font-size: 24px;
}

.kemPbI {
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 108px;
}

@media (min-width: 768px) {
      .kemPbI {
          margin-top: 20px;
      }
}

.dwIKcI {
  display: flex;
  place-content: center flex-start;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
}

.dwIKcI img {
  width: 100%;
  max-width: 193px;
}

.SlXwf {
  margin-top: 21px;
  flex-direction: column;
  padding-bottom: 20px;
  align-items: center;
}

@media (min-width: 768px) {
      .SlXwf {
          flex-direction: row;
          gap: 49px;
      }
}

.idBXai {
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: rgb(255, 255, 255);
  opacity: 1;
  white-space: nowrap;
  width: 100%;
}

@media (min-width: 768px) {
      .idBXai {
          width: unset;
      }
}
.idBXap {
  opacity: 0.6;
}
.bXVdZp {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
  font-size: 12px;
}

@media (min-width: 768px) {
  .bXVdZp {
      font-size: 12px;
  }
}

.btUIXF {
  display: none;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-end;
  -webkit-box-pack: end;
  gap: 26px;
}

@media (min-width: 768px) {
      .btUIXF {
          display: flex;
      }
}

.bbXSHf {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
  gap: 6px;
}

.dUVUMV {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  background-color: rgb(27, 27, 31);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
}

.active-menu {
  border-bottom: 1px solid rgb(255,255,255)
  
}

.gasTas {
  width: 180px;
  background-color: black;
  height: 48px;
  position: absolute;
  top: 0;
  right: 0;
}

@media (min-width: 768px) {
  .gasTas {
      width: 280px;
  }
}

.gasgxX {
  margin-top: 48px;
}

.gasgxY {
  margin-top: 248px;
  opacity: 1 !important;
  z-index: 45 !important;
}



.jLDguS {
  border-radius: 40px;
  width: 120px;
  height: 41px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  place-content: center;
  -webkit-box-pack: center;
  position: absolute;
  background: black;
  top: 50px;
}

@media (min-width: 768px) {
      .jLDguS {
          width: 120px;
          height: 62px;
      }
}

.jLDguS span {
  font-weight: 400;
  font-size: 35px;
  line-height: 80%;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
}

@media (min-width: 768px) {
      .jLDguS span {
          font-size: 32px;
      }
}

.AAysg {
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  place-content: center flex-start;
  -webkit-box-pack: start;
  position: relative;
  flex: 1 1 0%;
}

@media screen and (min-width: 768px) {
      .AAysg {
          padding-top: 57px;
      }
}

.hEgjjJ {
  background: url(./assets/images/VoltBg.7625d76b07678953ac7e.png) center top / 100% no-repeat;
  position: absolute;
  inset: 0px;
  display: none;
}

@media screen and (min-width: 768px) {
      .hEgjjJ {
          display: block;
      }
}

.gfasgS {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.blur-toke {
  position: absolute;
    width: 505px;
    height: 411px;
    border-radius: 50%;
    background: rgb(88, 170, 255);
    filter: blur(110px);
    right: 601px;
    top: 205px;
    z-index: 0;
}

.blur-about {
  position: absolute;
  width: 505px;
  height: 411px;
  border-radius: 50%;
  background: rgb(88, 170, 255);
  filter: blur(110px);
  left: 550px;
  top: 205px;
  z-index: 0;
}

.blur-arcade {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 605px;
  height: 511px;
  border-radius: 50%;
  background: rgb(231 87 187);
  filter: blur(95px);
  z-index: 0;
}

.blur-team {
  position: absolute;
    top: -120px;
    width: 605px;
    height: 511px;
    border-radius: 50%;
    background: rgb(88 170 255 / 60%);
    filter: blur(200px);
    z-index: 0;
}

.bg-black-t {
  background: rgba(0, 0, 0, 0.5);
}

.bg-image-blur {
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);
}

.animated-vtext {
  --bg-size: 450%;
  --color-one: hsl(0, 0%, 100%);
  --color-two: hsla(209, 95%, 55%);
  --color-three: rgb(242, 31, 249);
  background: linear-gradient(
    90deg,
    var(--color-one),
    var(--color-one),
    var(--color-two),
    var(--color-three),
    var(--color-one),
    var(--color-one)
  ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  animation: move-background 11s linear infinite;
}

@keyframes move-background {
  0% {
    background-position: 0 0; /* Start with white */
  }
  90.1% {
    background-position: 0 0; /* Hold white for 10 seconds */
  }
  100% {
    background-position: 200% 0; /* Transition to colors over 1 second */
  }
}

@media (prefers-reduced-motion: reduce) {
  .boujee-text {
    animation: none;
  }
}

.gUPXGA {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column-reverse;
}

@media (min-width: 768px) {
      .gUPXGA {
          flex-direction: row;
          padding-top: 185px;
          min-height: calc(906px);
      }
}

.bDIlDS {
  max-width: 1200px;
  width: 100%;
  display: flex;
  place-content: flex-start;
  align-items: flex-start;
  margin: 0px auto;
  padding: 0px 15px;
}

@media (min-width: 1200px) {
      .bDIlDS {
          padding: 24px 0px 24px 0px;
      }
}

@media (min-width: 768px) {
      .bbNTyu {
          justify-content: flex-end;
          align-items: center;
      }
}

.bbNTyu > * {
  z-index: 2;
}

.kOGaJo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  place-content: flex-start;
  gap: 54px;
}

@media (min-width: 768px) {
      .kOGaJo {
          gap: 41px;
      }
}

.hfIqJo {
  font-style: normal;
  margin: 0px;
  font-family: "aBlackLives", Sans-serif;
  font-weight: 400;
  font-size: 69px;
  line-height: 80%;
  letter-spacing: -0.04em;
  padding-bottom: 20px;
  text-transform: uppercase;
  color: rgb(217, 217, 217);
  position: relative;
  width: 100%;
  text-align: right;
}

@media (min-width: 768px) {
      .hfIqJo {
          width: unset;
          font-size: 99px;
          padding-bottom: 16px;
          line-height: 80%;
      }
}

.hfIqJo::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  max-width: 388px;
  background-color: rgba(255, 255, 255, 0.3);
  left: 0px;
  bottom: 0px;
}

.hfIqJo::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  left: 0px;
  bottom: -3px;
  max-width: 137px;
}

.gFFLVz {
  max-width: 433px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  place-content: flex-start;
  gap: 24px;
  text-align: right;
}

.ggDSuj {
  font-weight: 400;
  width: 100%;
  line-height: 160%;
  color: rgba(255, 255, 255, 0.5);
}

@media (min-width: 768px) {
      .ggDSuj {
          font-size: 16px;
      }
}


.ggDSuj span {
  padding: 2px 4px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.3);
}


.bxsnKu {
  position: relative;
  width: 450px;
  max-width: unset;
  left: 38%;
  transform: translateX(-35%);
  margin-bottom: 20px;

}

@media (min-width: 768px) {
      .bxsnKu {
          margin-bottom: unset;
          margin-top: unset;
          width: unset;
          position: absolute;
          top: 30px;
          left: calc(50% - 400px);
          transform: translateX(-50%);
          z-index: 1;
          max-width: 842px;
          width: 842px;
      }
}
.gynApa span {
  position: relative;
}

.gynApa span:after {
  background: url(./assets/images/dot-animation.svg) 0 0 no-repeat;
  clear: both;
  content: "";
  height: 20px;
  position: absolute;
  right: inherit;
  top: -5px;
  width: 20px;
}